

<template>

   

      <el-dialog  style=" border-radius: 6px; left: -150px; width: 400px; height: 250px; background-color: rgba(0,0,0,.3);" v-model="visible" :show-close="false">
        <template #header="{ close, titleId, titleClass }">
          <div class="my-header">
            <h6 :id="titleId" :class="titleClass" style="font-size: 15px; color: white;">Private Session</h6>
            <el-button type="danger" size="small" @click="close">
              <el-icon class="el-icon--left"><CircleCloseFilled /></el-icon>
              Close
            </el-button>
          </div>
        </template>
        <div class="main">
            <div><span style="color: #f56c6c;">{{ props.minutes }} </span> Credits per minute</div>
            <div></div>
            <div  @click="openBuyCredits" style="border-radius: 6px; cursor: pointer;  color: black; background-color: #eaee03; width: 200px;margin: auto">Get Credits</div>
        </div>
        <div style="margin-top:35px; text-align: center;">You have <span style="color: #f56c6c;">{{ props.price }}</span> Credits left on your account</div>
      </el-dialog>

      

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { ElButton, ElDialog } from 'element-plus'
import { CircleCloseFilled } from '@element-plus/icons-vue'
import { phoneStore } from '../../store/phone1';
import bus from 'vue3-eventbus'
const store = phoneStore()
const visible = ref(false)

const props = defineProps({
    minutes: {
        type: Number,
        require:true
  },
    price: {
    type: Number,
    require: true
  }
})


// watch(() => props.isShow, (newVal) => {
//     visible.value = newVal
// })

// watch(visible, (newVal) => {
//     if (newVal !== props.isShow) {
//         emit('update:isShow', newVal);
//     }
// });

onMounted(() => {
    console.log(props.minutes ,'props')
    console.log(store.balance)
})

//打开 买点数
const openBuyCredits = () => {
  visible.value = false
  bus.emit('openBuyCredits', true)
}

const show = () => {
  visible.value = true
}

defineExpose({
  show
})


</script>

<style scoped scss>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  /* background-color: red; */
}
.main{
    margin-top: -20px;
    color: white;
}
.main > div{
    height: 27px;
    line-height: 30px;
    text-align: center;
}

</style>