<template>
  <div class="timer">
    {{ nowTime }}
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
const timer = ref(null);
const nowTime = ref("");
const hour = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const tstamp0 = ref(null);

onMounted(() => {
  // tiem()
});

const stopTimers = ref(false);
const toZero = (timeNumber) => {
  return timeNumber < 10 ? "0" + timeNumber : timeNumber;
};
const time = () => {
  setInterval(() => {
    if (stopTimers.value) return;
    timer.value = setInterval(startTimer(), 1000);
  }, 1000);
};
onUnmounted(() => {
  clearInterval(timer.value);
});

const clearTimer = () => {
  clearInterval(timer.value);
};

const startTimer = () => {
  if (tstamp0.value == null) {
    tstamp0.value = new Date();
  }
  let deltaTime = (new Date() - tstamp0.value) / 1000;

  // console.log("deltaTime", deltaTime);
  hour.value = Math.trunc(deltaTime / 3600);
  // console.log("deltaTimehour.value", hour.value);
  minutes.value = Math.trunc((deltaTime - hour.value * 3600) / 60);
  // console.log("deltaTime minute.value", minutes.value);
  seconds.value = Math.trunc(deltaTime - hour.value * 3600 - minutes.value * 60);
  // console.log("deltaTime seconds.value", seconds.value);
  /*
  seconds.value += 1;
  if (seconds.value >= 60) {
    seconds.value = 0;
    minutes.value = minutes.value + 1;
  }
  if (minutes.value >= 60) {
    minutes.value = 0;
    hour.value = hour.value + 1;
  }
  */
  if (stopTimers.value) return;
  nowTime.value =
    toZero(hour.value) + ":" + toZero(minutes.value) + ":" + toZero(seconds.value);
};

defineExpose({ stopTimers, time });
</script>
<style scoped>
.timer {
  color: rgb(48, 205, 138);
  z-index: 999;
}
</style>
