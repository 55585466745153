<style lang="scss" scoped>
@media screen and (min-width: 1081px) {
  .moble {
    display: none;
  }
}

/* 移动端样式 */
@media screen and (max-width: 1080px) {
  .pc {
    display: none;
  }

  :deep(.el-drawer__header) {
    margin-bottom: 0px;
  }

  :deep(.el-dialog) {
    left: 0 !important;
  }

  :deep(.el-drawer__header) {
    margin-bottom: 0px;
  }

  :deep(.btt) {
    background-color: rgba(0, 0, 0, 0.3);
  }

  #myVideo {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }

  .mobleChart {
    position: absolute;
    bottom: 70px;
    left: 20px;
    width: 250px;
    height: 210px;
    background-color: rgba(0, 0, 0, 0.05);
    color: #ffffff;
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 6px;
  }

  .yd-all {
    width: 100%;
    height: 100%;
    background-color: skyblue;
    position: relative;
    overflow-y: hidden;

    .loadingBox {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      /* 正方形的宽度 */
      height: 100px;
      /* 正方形的高度，与宽度相同 */
      border-radius: 50%;
      z-index: 999;
    }

    .w-50 {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: 150px;
      height: 31px;
      line-height: 31px;
      color: #000000;
      background-color: yellow;
      text-align: center;
    }

    .mobleGift {
      position: absolute;
      bottom: 70px;
      right: 20px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
      text-align: center;

      p {
        height: 40px;
        line-height: 48px;
        text-align: center;
      }
    }

    .mobleCredits {
      position: absolute;
      bottom: 150px;
      right: 20px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
      text-align: center;

      p {
        height: 40px;
        line-height: 48px;
        text-align: center;
      }
    }

    .VideoCall {
      position: absolute;
      bottom: 400px;
      right: 20px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
      text-align: center;

      p {
        height: 40px;
        line-height: 48px;
        text-align: center;
      }
    }

    .logMessage {
      position: absolute;
      bottom: 250px;
      right: 20px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.2);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
      text-align: center;

      p {
        height: 40px;
        line-height: 48px;
        text-align: center;
      }
    }

    .expense {
      position: absolute;
      top: 10px;
      right: 20px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
      text-align: center;
      z-index: 401;

      p {
        height: 40px;
        line-height: 48px;
        text-align: center;
      }
    }

    .mobXiaoxi {
      position: absolute;
      bottom: 20px;
      left: 30px;
      background-color: rgba(0, 0, 0, 0.2);
      width: 40px;
      height: 32px;
      border-radius: 6px;
      color: red;
      text-align: center;

      p {
        height: 32px;
        line-height: 32px;
      }
    }

    .giftTitle {
      width: 100%;
      text-align: center;
      height: 40px;
      line-height: 40px;
      color: #fac53c;
    }

    .demo-image .block {
      // padding: 30px 0;
      text-align: center;
      // border-right: solid 1px var(--el-border-color);
      display: inline-block;
      width: 33%;
      box-sizing: border-box;
      vertical-align: top;
    }

    .demo-image .block:last-child {
      border-right: none;
    }

    .mobHeader {
      height: 0;
      width: 0;
    }

    .mobTimer {
      position: absolute;
      top: 20px;
      width: 100%;
      z-index: 400;

      div {
        width: 84.94px;
        margin: auto;
      }
    }

    .mobDown {
      position: absolute;
      bottom: 20px;
      width: 100%;

      div {
        width: 84.94px;
        margin: auto;
      }
    }
  }
}
</style>

<template>
  <div class="callplayer pc">
    <div>
      <navbar :payStatus="payStatus" @closPay="buyCredits(false)" />
    </div>
    <div class="middle">
      <div class="content">
        <div class="mid-video">
          <PromptBox ref="tips" :price="price" :minutes="minutes"></PromptBox>
          <div>
            <!-- <button @click="buttonTest">anniu</button> -->
            <!-- <button @click="buttonTest1">aniu1</button> -->
            <!-- <el-button type="danger" @click="stopTest">测试暂停</el-button>
            <el-button type="danger" @click="startTest">测试开始</el-button> -->
          </div>
          <div class="video" id="videoIdDom">
            <div class="video-top">
              <div v-if="spFlag" class="video-class" v-loading="loadingFlag" element-loading-text="connecting...">
                <video height="450" id="pcVideo" ref="remoteCameraVideo" @click="videoChange"></video>
                <div class="video-small">
                  <video id="smallPcVideo" height="150" ref="localCameraVideo" muted @click="videoChange"></video>
                </div>
              </div>
              <div class="video-gif" v-if="receiveType == 0">
                <ul>
                  <el-popover placement="top" effect="dark" :width="200" trigger="hover" v-for="(item, index) in gifArr"
                    :key="index">
                    <div class="gif-send">
                      <p class="send">{{ item.giftName }}</p>
                      <p>{{ item.coin }} {{ $t("Credits") }}</p>
                      <p>
                        <span v-if="userInfo?.coinBalance - Number(item.coin) < 0">
                          {{ $t("youdonthave") }}
                        </span>
                        <el-button style="width: 60px" color="#ffe400" size="small" @click="buyCredits(true)">
                          {{ $t("buyCredits") }}
                        </el-button>
                      </p>
                      <div style="
                          display: flex;
                          justify-content: space-between;
                          margin-top: 8px;
                        ">
                        <el-input-number v-model="num" :min="1" :max="100" />
                        <el-button style="width: 50%" color="#626aef" @click="postGiftRecord(item.id, item)">
                          {{ $t("gift") }}
                        </el-button>
                      </div>
                    </div>
                    <template #reference>
                      <li class="moji-item">
                        <div class="img-wrap" v-show="item.image">
                          <img :src="ImageUrl + item.image" alt="" />
                        </div>
                      </li>
                    </template>
                  </el-popover>
                </ul>
                <div class="buy-thing" @click="openBuyCredits">
                  {{ $t("BUYCREDITS") }}
                </div>

                <el-popover effect="dark" placement="top" :width="200" trigger="hover">
                  <template #reference>
                    <div style="
                        width: 80px;
                        line-height: 37px;
                        text-align: center;
                        background-color: #ffe400;
                        font-size: 12px;
                        cursor: pointer;
                      ">
                      Balance
                    </div>
                  </template>
                  <div class="balance">
                    <div>
                      <el-icon>
                        <RemoveFilled />
                      </el-icon>

                      <span style="margin:0 3px; vertical-align: 2.3px;">:</span>
                      
                      <span style="color:red; font-size:20px; padding-bottom:5px;">
                      
                      {{ userBalance.deduction }}</span>
                    </div>
                    <div>
                      <el-icon>
                        <Coin />
                      </el-icon>
                      <span style="margin:0 3px; vertical-align: 2.3px;">:</span>
                      
                      <span style=" font-size:20px;">{{ userBalance.balance }}</span>
                    </div>
                  </div>
                </el-popover>
              </div>
            </div>
            <div style="
                display: flex;
                align-items: center;
                width: 153px;
                justify-content: space-between;
                margin-top: 3px;
                padding-left: 5px;
              ">
              <timers ref="refTimers"></timers>
              <el-button @click="hangUp" type="danger">Hang Up</el-button>
            </div>
          </div>
          <div class="chat-all">
            <div class="chat-top">
              <div class="img">
                <img :src="ImageUrl + IMG" alt="" srcset="" />
              </div>
              <!-- <div class="chat-close">
                                <p><svg-icon icon="jiahao"></svg-icon></p>
                            </div> -->
            </div>
            <div class="messages">
              <ul id="msg-ul" ref="msglist">
                <li v-for="(item, index) in chatArr" :key="index">
                  <div v-if="item.type == 'gift'">
                    <div style="color: bisque; transform: scale(0.8); margin-left: -35px">
                      {{ ProcessingTime(ZTime(item.content.createTime)) }}
                    </div>
                    <span style="display: flex; align-items: center">{{ item.content.userName }}
                      {{ $t("presented") }}
                      {{ item.content.num }}
                      <img style="width: 14px; height: 14px; border-radius: 50%" :src="ImageUrl + item.content.image"
                        alt="" />
                    </span>
                  </div>
                  <div v-else>
                    <div style="color: bisque; transform: scale(0.8); margin-left: -35px">
                      {{ ProcessingTime(ZTime(item.createTime)) }}
                    </div>
                    <span v-if="item.user?.nickname">{{ item.user?.nickname }} : </span>
                    <!-- consultant?.nickname -->
                    <span v-if="item.consultant?.nickname">{{ item.consultant?.nickname }} :
                    </span>

                    <span style="word-break: break-all; text-align: justify">{{
                      item.content
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <!-- sendText -->
            <div class="send-text">
              <div style="display: flex">
                <el-input v-model="sendText" style="width: 80%" @keydown.enter="postRoomMsg"
                  placeholder="Say Something" />
                <div style="width: 20%; text-align: center; background-color: #ffff00">
                  <el-button color="yellow" @click="postRoomMsg">Send</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="localUserMedia">
      用户： {{ objVie.user }}次数:<span style="color: red">{{ NumberCount }}</span>
      ---顾问： {{ objVie.guwen }}次数:<span style="color: red">{{ NumberCount }}</span>
    </div> -->
    <div>
      <Footer></Footer>
    </div>
  </div>

  <div>
    <el-dialog v-model="isCameraShow" title="Tips" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" width="400px">
      <span>Failed To Get The Camera</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="hangUp1"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>
  </div>

  <!-- 移动端 -->
  <div v-if="!spFlag" class="moble yd-all">
    <div class="mobHeader">
      <navbar :payStatus="payStatus" @closPay="buyCredits(false)" />
    </div>

    <div>
      <PromptBox ref="tips" :price="price" :minutes="minutes"></PromptBox>
    </div>
    <div class="loadingBox" v-loading="loadingFlag" element-loading-text="connecting..."></div>
    <div :style="{ width: mobileSpWidth + 'px', height: mobileSpHeight + 'px' }">
      <video id="myVideo" :width="mobileSpWidth" :height="mobileSpHeight" ref="remoteCameraVideo" @click="videoChange"
        :muted="!isMuted" controls autoplay controlslist playsinline></video>
    </div>

    <div id="mySmallVideoId" style="position: absolute; left: 0px; top: -32px; z-index: 300">
      <video controls id="mySmallVideo" :width="mobileSpWidth / 2" :height="mobileSpHeight / 4" ref="localCameraVideo"
        :muted="isMuted" @click="videoChange"></video>
    </div>

    <!-- 电话计时和按钮 -->
    <div class="mobTimer">
      <div>
        <timers ref="refTimers"></timers>
      </div>
    </div>
    <div class="mobDown">
      <div>
        <el-button @click="hangUp" type="danger">Hang Up</el-button>
      </div>
    </div>

    <div class="mobXiaoxi">
      <p><svg-icon @click="mobleTextFn" icon="xiaoxi"></svg-icon></p>
    </div>

    <div v-if="getType() !== '1'" class="mobleGift" @click="sendGift">
      <p>
        <img style="width: 20px; height: 20px" src="@/assets/gift01.png" alt="" />
      </p>
    </div>

    <el-popover effect="dark" placement="top" :width="200" trigger="click">
      <template #reference>
        <!-- 当前费用明细  -->
        <div v-if="getType() !== '1'" class="expense">
          <p>
            <el-icon>
              <Coin />
            </el-icon>
          </p>
        </div>
      </template>
      <div class="balance">
        <div>
          <el-icon>
            <RemoveFilled />
          </el-icon><span style="padding-bottom: 5px; color: red">{{
            userBalance.deduction
          }}</span>
        </div>
        <div>
          <el-icon>
            <Coin />
          </el-icon><span style="padding-bottom: 15px">{{ userBalance.balance }}</span>
        </div>
      </div>
    </el-popover>

    <!-- 点数  -->
    <div v-if="getType() !== '1'" class="mobleCredits" @click="buyCredits(true)">
      <p><img style="width: 20px; height: 20px" src="@/assets/pay01.png" alt="" /></p>
    </div>

    <!-- log信息  -->
    <div class="logMessage" v-if="testIsShow == 'test'" @click="isLogMessage = !isLogMessage">
      <p>
        <el-icon>
          <Edit />
        </el-icon>
      </p>
    </div>

    <!-- 视频互换  -->
    <div class="VideoCall" @click="onVideoCall">
      <p>
        <el-icon>
          <Refresh />
        </el-icon>
      </p>
    </div>

    <!-- 聊天内容 -->
    <div ref="advisorChatRef" class="mobleChart">
      <div v-for="(item, index) in chatArr" :key="index">
        <div style="height: 40px; line-height: 40px" v-if="item.type == 'gift'">
          <div style="position: relative">
            <span style="position: absolute; left: 5px; top: 4px">
              <el-avatar :size="23" :src="item.user?.avatar
                ? ImageUrl + item.user?.avatar
                : require('@/assets/new_performer_bg_experts_1024x768.png')
                " />
            </span>
            <span style="margin-left: 35px">{{ item.content.userName }} : </span>
            <span>{{ $t("presented") }}</span>
            <span> {{ item.content.num }}:</span>
            <span><img style="width: 14px; height: 14px; border-radius: 50%" :src="ImageUrl + item.content.image"
                alt="" /></span>
          </div>
        </div>
        <div style="line-height: 25px; margin: 5px 0" v-else-if="item">
          <div style="position: relative">
            <span style="position: absolute; left: 5px; top: 0px">
              <el-avatar :size="23" :src="item.user?.avatar
                ? ImageUrl + item.user?.avatar
                : require('@/assets/new_performer_bg_experts_1024x768.png')
                " />
            </span>
          </div>
          <span style="margin-left: 35px; height: 40px" v-if="item.consultant">{{
            item.consultant.nickname
          }}</span>
          <span style="margin-left: 35px; color: rgb(251, 217, 204); height: 40px" v-if="item.user">{{ item.user.nickname
          }}</span>
          :<span style="margin-left: 10px">{{ item.content }}</span>
        </div>
      </div>
    </div>

    <!-- 聊天弹窗 -->
    <el-drawer size="30%" v-model="liaotian" direction="btt">
      <div style="margin-top: 30px">
        <el-input style="width: 80%" v-model="sendText" placeholder="Say Something" />
        <el-button color="yellow" @click="postRoomMsg">Send</el-button>
      </div>
    </el-drawer>

    <!-- 礼物弹窗 -->
    <el-drawer size="50%" v-model="isGift" title="Gift" direction="btt">
      <div class="giftTitle">Send surprise</div>
      <div class="demo-image">
        <div v-for="(item, index) in gifArr" :key="index" class="block">
          <div>{{}}</div>
          <div>${{ item.coin }}</div>
          <el-image @click="tsetImg(item)" style="width: 100px; height: 100px" :src="ImageUrl + item.image" fit="cover" />

          <div>
            <el-drawer size="50%" v-model="innerDrawer" :title="oneGift.giftName" :append-to-body="true" direction="btt"
              @close="innerDrawer = false">
              <div style="display: flex; justify-content: space-between; align-items: center">
                <p>
                  <el-image style="width: 70px; height: 70px" :src="ImageUrl + oneGift.image" fit="cover" />
                </p>
                <p>{{ oneGift.coin * num }}</p>
                <p><el-input-number v-model="num" :min="1" :max="100" /></p>
              </div>

              <div>
                <el-button style="width: 98%" color="#626aef" @click="postGiftRecord(oneGift.id, oneGift)">
                  {{ $t("gift") }}
                </el-button>
              </div>

              <div></div>
            </el-drawer>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- log弹窗 -->
    <el-drawer size="100%" v-model="isLogMessage" title="Log message" direction="btt">
      <div style="background-color: #000000; color: #ffffff">
        <div>用户： {{ objVie.user }}</div>
        <div>顾问： {{ objVie.guwen }}</div>
        <div>连接次数： {{ NumberCount }}</div>
        <div>摄像头： {{ objVie.camera }}</div>
        <div>
          <h2>peerjs</h2>
          <ul>
            <li>peer:{{ objVie.peerOn.peer }}</li>
            <li>connectionId:{{ objVie.peerOn.connectionId }}</li>
            <li>------------------</li>
          </ul>
          <div v-for="(item, index) in logArr" :key="index">
            <li>{{ item }}</li>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>

  <el-dialog v-model="isRate" title="Tips" :width="spFlag ? '400px' : '90%'" :before-close="handleClose">
    <div class="comment">
      <h3 class="com-head">Leave a review of your anchor</h3>
      <div class="com-infor">
        <div class="infor-left">
          <p>name</p>
        </div>
        <div class="infor-right">
          <el-rate v-model="commentScore" :texts="['oops', 'disappointed', 'normal', 'good', 'great']" show-text />
        </div>
      </div>
      <el-input v-model="commenText" :autosize="{ minRows: 5, maxRows: 4 }" type="textarea"
        placeholder="Please leave your comment" />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="saveComment"> Confirm </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { RemoveFilled, Coin, Edit, Refresh } from "@element-plus/icons-vue";
import { closeLive } from "@/api/profileInfo";
import Navbar from "../.././layout/components/Navbar.vue";
import Footer from "../.././layout/components/Footer.vue";
import timers from "../../components/timers/index.vue";
import { joinRoom } from "@/api/messageCall";
import { getToken, getType } from "@/utils/auth";
import { page1Store } from "@/store/page1";
import { phoneStore } from "@/store/phone1";
import request from "@/utils/request.js";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import {
  ref,
  onMounted,
  inject,
  watch,
  computed,
  onBeforeUnmount,
  nextTick,
  reactive,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import bus from "vue3-eventbus";
import { fa, tr } from "element-plus/es/locale";
import PromptBox from "./promptBox.vue";
import move from "@/i18n/move.js";

//手机端是否静音
const isMuted = ref(true);
//计时器是否开始
// const isTimer = ref(false);

const logArr = reactive([]);

//用户顾问视频信息
const objVie = reactive({
  user: "",
  guwen: "",
  camera: "",
  peerOn: {},
  msg: "",
});
let NumberCount = ref(0);

const testid = ref();
const isCameraShow = ref(false);
const userBalance = reactive({
  deduction: 0,
  balance: 0,
});
const page1StoreDa = page1Store();
let webSocketMsg = computed(() => {
  return page1StoreDa.webSocketMsg;
});
const spFlag = ref(null);

const loadingFlag = ref(true);
const connectNum = ref(0);

// ------------移动端-------------------

const testIsShow = ref(false);
const isShow = ref(true);
const mobileSpWidth = ref(
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
);
const mobileSpHeight = ref(
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight
);
const liaotian = ref(false);
const oneGift = ref({}); //单个礼物的详细信息
const isGift = ref(false);
const isLogMessage = ref(false);
const innerDrawer = ref(false);
// ---------------------------------------
const store1 = phoneStore();
const token = getToken();
let route = useRoute();
let router = useRouter();

// var peer = new Peer() //wom

var peer = new Peer({
  config: {
    iceTransportPolicy: "relay",
    iceServers: [
      {
        urls: "turn:relay1.expressturn.com:3478",
        username: "efX724U0564BWVXK9W",
        credential: "f00NUUVCrPOmIng6",
      },
    ],
  },
});
const num = ref(1);
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const socket = inject("socket");
let ws;
const payStatus = ref(false);
const localCameraVideo = ref();
const remoteCameraVideo = ref();
const localUserMedia = ref();
const gifArr = ref("");
const chatArr = ref([]);
const receiveValue = ref(""); //返回的Id
const msglist = ref("");
const advisorChatRef = ref("");
const sendText = ref("");
const roomName = ref("");
const isRate = ref(false);
const commentScore = ref("5");
const receiveType = ref("");
const commenText = ref("");
const roomId = ref("");
const consultantId = ref("");
const intervalTime = ref("");
const minutes = ref(0);
const IMG = ref("");
var content = ref(false);
var whoAmI = ref("");
var connLimit = ref(20);
const tips = ref(null);
const price = ref(0);
const refTimers = ref(null);
const isNoCamera = ref(false);
const buttonTest = () => {
  tips.value.show();
  console.log(tips.value.price);
};
const CameraA = ref(false);
const CameraB = ref(false);
let detectionTimer = ref(null);

const testBox = ref(null);
const Disx = ref(0);
const Disy = ref(0);
const x = ref(0);
const y = ref(0);

const onVideoCall = () => {
  if (localCameraVideo.value && remoteCameraVideo.value) {
    [remoteCameraVideo.value.srcObject, localCameraVideo.value.srcObject] = [
      localCameraVideo.value.srcObject,
      remoteCameraVideo.value.srcObject,
    ];
    startTest();
    isMuted.value = !isMuted.value;
  }
};

const FnStart = function (ev) {
  Disx.value = ev.pageX - x.value;
  Disy.value = ev.pageY - y.value;

  document.onmousemove = FnMove;
  document.onmouseup = FnUp;
};

const FnMove = function (ev) {
  let a = document.getElementById("smallPcVideo");
  let rect = a.getBoundingClientRect();
  let maxX = window.innerWidth - rect.width;
  let maxY = window.innerHeight - rect.height;

  x.value = Math.min(Math.max(ev.pageX - Disx.value, 0), maxX);
  y.value = Math.min(Math.max(ev.pageY - Disy.value, 0), maxY);

  a.style.left = x.value + "px";
  a.style.top = y.value + "px";

  ev.preventDefault && ev.preventDefault();
};

const FnUp = function () {
  document.onmousemove = null;
  document.onmouseup = null;
};

const stopTest = () => {
  console.log("11");
  if (spFlag.value) {
    let video = document.getElementById("pcVideo");
    let smallPcVideo = document.getElementById("smallPcVideo");
    //smallPcVideo
    console.log(video, "video");
    // 暂停视频的音频
    video.muted = true;
    video.pause();
    // smallPcVideo.muted = true;
    smallPcVideo.pause();
  } else {
    //  mySmallVideo myVideo
    let mobvideo = document.getElementById("myVideo");
    let mobsmallPcVideo = document.getElementById("mySmallVideo");
    // 暂停视频的音频
    mobvideo.muted = true;
    mobvideo.pause();
    // mobsmallPcVideo.muted = true;
    mobsmallPcVideo.pause();
  }
};

const startTest = () => {
  console.log("22");
  if (spFlag.value) {
    let video = document.getElementById("pcVideo");
    let smallPcVideo = document.getElementById("smallPcVideo");
    //smallPcVideo
    console.log(video, "video");
    // 开启视频的音频
    video.muted = false;
    video.play();
    // smallPcVideo.muted = false;
    smallPcVideo.play();
  } else {
    //  mySmallVideo myVideo
    let mobvideo = document.getElementById("myVideo");
    let mobsmallPcVideo = document.getElementById("mySmallVideo");
    // 开启视频的音频
    mobvideo.muted = false;
    mobvideo.play();
    // mobsmallPcVideo.muted = false;
    mobsmallPcVideo.play();
  }
};

const isGiftShow = () => {
  isGift.value = false;
};

const mobleTextFn = () => {
  console.log("111");
  liaotian.value = true;
};

const sendGift = () => {
  isGift.value = true;
};

const tsetImg = (item) => {
  innerDrawer.value = true;
  oneGift.value = item;
  console.log(item, "2222");
};

watch(window.innerHeight, (n, o) => {
  mobileSpHeight.value = n;
});

const videoChange = () => {
  console.log("1112111");
};

const ProcessingTime = (time) => {
  const date = new Date(time);
  const month = date.getMonth() + 1; // 月份从0开始，所以需要加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const paddedHours = hours < 10 && hours >= 0 ? `0${hours}` : hours;
  const paddedMinutes = minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 && seconds >= 0 ? `0${seconds}` : seconds;
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

const ZTime = (time) => {
  const diff = new Date().getTimezoneOffset() * 60 * 1000;
  let timestamp = new Date(time).getTime() - diff;
  // 根据毫秒数构建 Date 对象
  let date = new Date(timestamp);
  // 格式化日期
  let dateTime = date.toLocaleString();
  dateTime = dateTime.substring();
  return dateTime;
};

watch(
  () => window.innerWidth,
  (n) => {
    console.log(n, "mm,nn");
    if (n > 1080) {
      spFlag.value = true;
    } else {
      spFlag.value = false;
    }
  }
);

watch(NumberCount, (newCount) => {
  console.log(newCount, "一对一重连次数");
});

// 创建一个函数来获取屏幕宽度
function getScreenWidth() {
  return (
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  );
}

// 创建一个函数来获取屏幕宽度
function getScreenHeight() {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );
}

// 创建一个函数来处理宽度变化事件
function handleScreenWidthChange() {
  const screenWidth = getScreenWidth();
  const screenHeight = getScreenHeight();
  // console.log(`屏幕宽度: ${screenWidth}px`);
  if (screenWidth > 1080) {
    spFlag.value = true;
  } else {
    spFlag.value = false;
    mobileSpWidth.value = screenWidth;
    mobileSpHeight.value = screenHeight;
  }
}

const phoneDrag = () => {
  let videoElement = document.getElementById("mySmallVideoId");
  let touchStartX, touchStartY;
  let videoStartX, videoStartY;
  videoElement.addEventListener("touchstart", function (event) {
    console.log(event.touches, "event.touches");
    // 阻止默认的触摸事件行为
    event.preventDefault();
    // 获取第一个触摸点的位置
    touchStartX = event.touches[0].clientX;
    touchStartY = event.touches[0].clientY;

    // 获取视频元素的当前位置
    let rect = videoElement.getBoundingClientRect();
    console.log(rect, "rect");
    videoStartX = rect.left;
    videoStartY = rect.top;
  });

  videoElement.addEventListener("touchmove", function (event) {
    // 阻止默认的触摸事件行为
    event.preventDefault();

    // 计算移动的距离
    let deltaX = event.touches[0].clientX - touchStartX;
    let deltaY = event.touches[0].clientY - touchStartY;

    // 计算新的位置
    let newLeft = videoStartX + deltaX;
    let newTop = videoStartY + deltaY;

    // 获取屏幕的宽度和高度
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    // 确保新位置在屏幕范围内
    newLeft = Math.max(0, Math.min(newLeft, screenWidth - videoElement.offsetWidth));
    newTop = Math.max(-32, Math.min(newTop, screenHeight - videoElement.offsetHeight));

    // 移动视频元素到新的位置
    videoElement.style.left = newLeft + "px";
    videoElement.style.top = newTop + "px";
  });

  videoElement.addEventListener("touchend", function () {
    // 清除触摸起始位置和视频元素初始位置的数据
    touchStartX = null;
    touchStartY = null;
    videoStartX = null;
    videoStartY = null;
  });
};

onMounted(async () => {
  closeLoading();
  window.addEventListener("beforeunload", () => {
    let pathname = route.path;
    if (pathname == "/callPlayer/receive") {
      let e = window.event || e;
      e.returnValue = "Are you sure you want to leave the current page?";
    }
  });

  console.log(route.path, "router.path");
  //电话弹窗的等待和关闭
  store1.isPhoneShow = false;
  store1.isCallLoading = false;
  // 添加窗口大小变化事件监听器
  window.addEventListener("resize", handleScreenWidthChange);

  // 初始加载时获取并显示屏幕宽度
  handleScreenWidthChange();

  console.log(tips.value);
  console.log(route.query);
  Counselor(route.query.consultantId);
  roomId.value = route.query.roomId;
  receiveType.value = route.query.type;
  roomName.value = route.query.roomName;
  consultantId.value = route.query.consultantId;
  //进入房间
  getRoomList();
  ws = socket("1", token);
  joinRoomIndex(route.query.roomName);
  await getCamera();
  // initWebsocket();

  nextTick(() => {
    detectionVideo();
    if (!spFlag.value) {
      phoneDrag();
    }
  });
});
onBeforeUnmount(() => {
  clearInterval(intervalTime.value);
  refTimers.value.clearTimer;
});
//获取顾问详情
const Counselor = async (id) => {
  const { code, data } = await request.get(
    "/system/faConsultant/info?consultantId=" + id
  );
  if (code == 200) {
    console.log(data, "顾问详情数据");
    minutes.value = data.perMinute;
    IMG.value = data.listImage;
    console.log(IMG.value, "IMG.value");
  }
};

//提交值
const saveComment = async () => {
  store1.leaveVideoFlag = true;
  isRate.value = false;
  let data = {
    consultantId: consultantId.value,
    rating: commentScore.value,
    comment: commenText.value,
  };
  let res = await request.post("/system/faConsultant/saveConsultantAppraise", data);
  if (res.code == 200) {
    router.push({ path: "/" });
  }
};
const getRoomList = () => {
  getGifArr();
  getHistoryMsg();
};
// 获取礼物信息
const getGifArr = async () => {
  const { rows } = await request.get("/system/FaGift/list?status=1");
  gifArr.value = rows;
};
// 打开购买积分
function buyCredits(e) {
  payStatus.value = e;
}
const handleClose = () => {
  store1.leaveVideoFlag = true;
  isRate.value = false;
  router.push("/");
};
const hangUp1 = () => {
  isCameraShow.value = false;
  store1.leaveVideoFlag = true;
  if (localUserMedia.value) {
    localUserMedia.value.getTracks().forEach((track) => {
      track.stop();
    });
    localCameraVideo.value.srcObject.getTracks().forEach((track) => {
      track.stop();
    });
    remoteCameraVideo.value.srcObject = null;
    localCameraVideo.value.srcObject = null;
  }

  peer.destroy();
  if (route.query.type == 0) {
    let msg = {
      type: "close",
      msg: "0",
    };
    ws.send(JSON.stringify(msg));

    isRate.value = true;
    refTimers.value.stopTimers = true;
  } else if (route.query.type == 1) {
    store1.leaveVideoFlag = true;
    let msg = {
      type: "close",
      msg: "1",
    };
    ws.send(JSON.stringify(msg));
    store1.phoneLog = {};

    router.push("/");
  }
  closeLive({}).then((res) => {
    if (res.code == 200) {
      ElMessage({ type: "success", message: "success" });
    } else {
      ElMessage({ type: "error", message: "error" });
    }
  });
};
//挂断通话接口
const hangUp = async () => {
  ElMessageBox.confirm("Are you sure you want to end this call?", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(() => {
      store1.leaveVideoFlag = true;
      if (localUserMedia.value) {
        console.log(localUserMedia.value, "这个是摄像头吗??");
        localUserMedia.value.getTracks().forEach((track) => {
          console.log(track, "tracktracktrack");
          track.stop();
        });

        localCameraVideo.value.srcObject.getTracks().forEach((track) => {
          track.stop();
        });
        remoteCameraVideo.value.srcObject = null;
        localCameraVideo.value.srcObject = null;
      }

      peer.destroy();
      if (route.query.type == 0) {
        let msg = {
          type: "close",
          msg: "0",
        };
        ws.send(JSON.stringify(msg));

        isRate.value = true;
        refTimers.value.stopTimers = true;
      } else if (route.query.type == 1) {
        store1.leaveVideoFlag = true;
        let msg = {
          type: "close",
          msg: "1",
        };
        ws.send(JSON.stringify(msg));
        store1.phoneLog = {};
        router.push("/");
      }
      closeLive({}).then((res) => {
        if (res.code == 200) {
          ElMessage({ type: "success", message: "success" });
        } else {
          ElMessage({ type: "error", message: "error" });
        }
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "Cancel the hang up",
      });
    });
};
//用户进房间发送peerId
const joinRoomIndex = async (roomName) => {
  let data = {
    consultantId: consultantId.value,
    roomName: roomName,
  };

  let res = await joinRoom(data);
  if (res.code == 200) {
    if (res.data.isEnd == "0") {
      testIsShow.value = res.msg;
    } else {
      ElMessage.error("The current room is closed and is returning to the home page.");
      store1.leaveVideoFlag = true;
      router.push("/");
    }
  }

  if (route.query.type == 0) {
    if (peer) {
      peer.destroy();
    }
    console.log("调用这个？");

    // peer = new Peer(); //wom

    peer = new Peer({
      config: {
        iceTransportPolicy: "relay",
        iceServers: [
          {
            urls: "turn:relay1.expressturn.com:3478",
            username: "efX724U0564BWVXK9W",
            credential: "f00NUUVCrPOmIng6",
          },
        ],
      },
    });
    peer.on("open", (peerId) => {
      let msg = {
        msg: peerId,
        type: "peerId",
        Camera: CameraA.value,
      };
      console.log("创建peerID");
      ws.send(JSON.stringify(msg));
    });
  }
};

//开始计费时间接口
const startCharge = async (num) => {
  await request.get(
    "/system/liveRoom/updateStartTime?roomName=" + roomName.value + "&num=" + num
  );
};
//当前时间转化
const transformTime = () => {
  // 创建一个 Date 对象
  let currentDate = new Date();

  // 获取小时、分钟和秒
  let hours = currentDate.getHours();
  let minutes = currentDate.getMinutes();
  let seconds = currentDate.getSeconds();

  // 如果时间小于 10，则在前面补充 "0"
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

//连接接口
const startCall = () => {
  connectNum.value++;
  whoAmI.value = "receiver";
  console.log(transformTime(), whoAmI.value);
  console.log(transformTime(), "-------Peer.startCall-----");
  logArr.push(transformTime(), "-------Peer.startCall-----");
  console.log(receiveValue.value, "receiveValue.value");

  if (localUserMedia.value) {
    logArr.push("本地视频是开启状态");
    let msg = { type: "peer.call" };
    ws.send(JSON.stringify(msg)); //发送准备call msg
  } else {
    if (peer) {
      peer.destroy();
    }
    // peer = new Peer(); //wom

    peer = new Peer({
      config: {
        iceTransportPolicy: "relay",
        iceServers: [
          {
            urls: "turn:relay1.expressturn.com:3478",
            username: "efX724U0564BWVXK9W",
            credential: "f00NUUVCrPOmIng6",
          },
        ],
      },
    });
    peer.on("open", (peerId) => {
      let msg = {
        msg: peerId,
        type: "peerId",
        Camera: CameraA.value,
      };
      console.log("创建peerID");
      ws.send(JSON.stringify(msg));
    });
  }
};

const userN = ref(false);
const guwenN = ref(false);

const mediaCall = () => {
  var conn = peer.connect(receiveValue.value);
  let connect = {
    type: "connect",
    msg: { Camera: CameraA.value },
  };
  ws.send(JSON.stringify(connect));
  conn.on("open", function () {
    // here you have conn.id
    conn.send("hi!");
  });
  conn.on("data", function (data) {
    content.value = true;
  });

  console.log(localUserMedia.value, "localUserMedia.value");

  var call = peer.call(receiveValue.value, localUserMedia.value);
  console.log(transformTime(), "-------Peer.call-----");
  logArr.push(transformTime(), "-------Peer.call-----");
  console.log(remoteCameraVideo.value.readyState, "readyState1");
  logArr.push("readyState1", remoteCameraVideo.value.readyState);

  call.on("stream", function (remoteStream) {
    console.log(transformTime(), remoteStream, "Peer.remoteStream");
    remoteCameraVideo.value.srcObject = remoteStream;
    //顾问那边
    logArr.push(transformTime(), "Peer.remoteStream拿到视频那边的流:");

    console.log(remoteCameraVideo.value.readyState, "readyState2");
    logArr.push("readyState2", remoteCameraVideo.value.readyState);
    objVie.guwen = remoteStream.id;

    remoteCameraVideo.value.play().then(
      () => {
        // 播放成功
        console.log("视频播放已经开始guwen");
        console.log(remoteCameraVideo.value.readyState, "readyState3");
        logArr.push("readyState3", remoteCameraVideo.value.readyState);
        // console.log("remoteCameraVideo.value",remoteCameraVideo.value.re)
        logArr.push("顾问视频开始播放");
        loadingFlag.value = false;
      },
      (error) => {
        // 播放失败，处理错误
        console.log(remoteCameraVideo.value.readyState, "readyState4");
        console.error(transformTime(), "视频播放失败:guwen", error);
        logArr.push(transformTime(), "视频播放失败:guwen");
      }
    );

    console.log("难道是这里??");
  });
  let msg = {
    type: "test",
  };
  ws.send(JSON.stringify(msg));
};

const numY = ref(0);
//ws
const initWebsocket = () => {
  let msg = {
    type: "live",
  };
  intervalTime.value = setInterval(() => {
    ws.send(JSON.stringify(msg));
  }, 30 * 1000);

  console.log(new Date(), "new Date2");
  ws.onmessage = async ({ data }) => {
    console.log("data++++", data);
    if (data[0] == "{") {
      let dataParse = JSON.parse(data);
      let msg1, msg2, msg3, msgData;

      switch (dataParse.type) {
        case "peerId":
          objVie.msg = dataParse.msg;
          console.log(dataParse.Camera, "dataParse.Camera***");
          console.log(CameraA.value, "CameraA.value***");
          if (!dataParse.Camera || !CameraA.value) {
            console.log("????");
            startCall();
            receiveValue.value = dataParse.msg;
            logArr.push("获取Peerjs参数id");
          }
          break;

        case "peer.receiverReady":
          logArr.push(transformTime(), "mediaCall调用--");
          mediaCall();
          break;

        case "peer.call":
          console.log(transformTime(), "peer.接受connection");
          logArr.push(transformTime(), "peer.接受connection");

          peer.on("connection", function (conn) {
            objVie.peerOn = conn;
            console.log(transformTime(), conn, "[Peer.conn]");
            logArr.push(transformTime(), "[Peer.conn]");

            conn.on("data", function (data) {
              console.log(transformTime(), data, "[Peer.onData]");
              logArr.push(transformTime(), "[Peer.onData]这里已经成功content值也为true");
              // Will print 'hi!'
              content.value = true;
              console.log(data);

              conn.send("hi back!"); //vin
            });
          });
          console.log("最后一步");

          peer.on("call", (mediaConnection) => {
            console.log(mediaConnection, "mediaConnectionmediaConnection");
            console.log("成功");

            //通过mediaConnection相应给对方自己的媒体信息
            mediaConnection.answer(localUserMedia.value);
            console.log(localUserMedia.value, "localUserMedia.value---");
            testid.value = localUserMedia.value.id;
            //当得到流时调用，remoteUserMedia 是对方的流媒体
            mediaConnection.on("stream", (remoteCameraStream) => {
              numY.value++;
              console.log(
                transformTime(),
                remoteCameraStream.id,
                "Peer.remoteCameraStream -- receiver side"
              );
              if (remoteCameraVideo.value) {
                console.log(remoteCameraVideo.value.readyState, "readyState");
                logArr.push("readyState", remoteCameraVideo.value.readyState);
                remoteCameraVideo.value.srcObject = remoteCameraStream;

                //用户那边？
                objVie.user = remoteCameraStream.id;
                if (numY.value == 1) {
                  startCharge(NumberCount.value);
                }
                logArr.push(transformTime(), "拿到顾问视频的流:--");
                console.log(
                  transformTime(),
                  remoteCameraVideo.value,
                  "Peer.remoteCameraStream -- receiver side -- before play()"
                );
                // remoteCameraVideo.value.play();
                console.log(remoteCameraVideo.value.readyState, "readyState");
                remoteCameraVideo.value.play().then(
                  () => {
                    // 播放成功
                    console.log(remoteCameraVideo.value.readyState, "readyState3");
                    logArr.push("readyState3", remoteCameraVideo.value.readyState);
                    if (remoteCameraVideo.value.readyState == 4) {
                      let UseTimemsg = { type: "timeStart" };
                      ws.send(JSON.stringify(UseTimemsg));
                    }
                    console.log("视频播放已经开始User");
                    logArr.push("用户视频开始播放");
                    loadingFlag.value = false;
                  },
                  (error) => {
                    // 播放失败，处理错误
                    console.error(transformTime(), "视频播放失败:User", error);
                    logArr.push(transformTime(), "视频播放失败:User");
                  }
                );
                console.log(
                  transformTime(),
                  remoteCameraStream.id,
                  "Peer.remoteCameraStream -- receiver side -- after play()"
                );
              }
            });
          });
          peer.on("error", (error) => {
            console.error("PeerJS 错误:", error);
          });

          msg3 = { type: "peer.receiverReady" };
          ws.send(JSON.stringify(msg3));
          logArr.push(transformTime(), "peer.receiverReady发送给顾问");

          break;

        case "timeStart":
          console.log("此时告诉顾问已经拿到视频流");
          msg1 = { type: "timeStart1" };
          ws.send(JSON.stringify(msg1));
          break;

        case "timeStart1":
          console.log("顾问收到并告诉用户,计时开始");
          if (!userN.value) {
            refTimers.value.time();
            userN.value = true;
          }
          msg2 = { type: "timeStart2" };
          ws.send(JSON.stringify(msg2));
        case "timeStart2":
          console.log("顾问收到,计时开始");
          if (!guwenN.value) {
            refTimers.value.time();
            guwenN.value = true;
          }
          break;
        case "test":
          console.log("接受connection");
          break;
        case "reminder":
          tips.value.show();
          break;
        case "inform":
          loadingFlag.value = false;
          ElMessage({
            message: "Video loading failure!",
            type: "warning",
          });
          break;
        case "stopVideo":
          stopTest();
          refTimers.value.stopTimers = true;
          break;
        case "runVideo":
          startTest();
          refTimers.value.stopTimers = false;
          break;
        case "coinBalance":
          console.log("进入");
          let data = JSON.parse(dataParse.message);
          let user = data.message;
          console.log(data.message, "dataParse.message");
          userBalance.balance = Number(user.coinBalance);
          userBalance.deduction = Number(user.consumption);
          // console.log(user.testIsShow, "user.testIsShow");
          // testIsShow.value = user.testIsShow;
          price.value = Number(user.coinBalance);
          break;
        case "connect":
          console.log("+++dataParse+++", dataParse);
          whoAmI.value = "caller"; //receiver
          console.log(content.value, "content.value每一次的布尔");
          logArr.push(
            transformTime(),
            "content:" + content.value,
            "dataParse:" + dataParse.msg.Camera,
            "CameraA:" + CameraA.value
          );

          if (!content.value && (!dataParse.msg.Camera || !CameraA.value)) {
            console.log("没进入?");
            setTimeout(() => {
              if (whoAmI.value == "receiver") {
                //caller
                //receiver
                logArr.push(transformTime(), "完成");
                return;
              } else {
                console.log(transformTime(), "Peer. 每次的进入时间");
                NumberCount.value++;
                console.log(transformTime(), "Peer. setTimeout", content.value);
                logArr.push(
                  transformTime(),
                  "content.value--Peer. setTimeout",
                  content.value
                );
                if (!content.value) {
                  let msg = {
                    type: "reconnect",
                  };
                  ws.send(JSON.stringify(msg));
                  console.log(transformTime(), "Peer. reconnect");
                  logArr.push(transformTime(), "Peer. reconnect");
                }
              }
            }, 10000 + Math.random(2000));
          } else {
            let msg = {
              type: "success",
            };
            ws.send(JSON.stringify(msg));
          }
          break;
        case "reconnect":
          console.log("这里?");
          console.log("---startCall---");
          if (connLimit.value > 0) {
            connLimit.value--;
            startCall();
          } else {
            ElMessage({ type: "error", message: "call  Error" });
            hangUp();
          }

          break;
        case "close":
          if (dataParse.msg == "0") {
            store1.leaveVideoFlag = true;
            router.push("/");
          } else {
            store1.leaveVideoFlag = true;
            refTimers.value.stopTimers = true;
            isRate.value = true;
          }
          break;
        case "chat":
          msgData = dataParse;
          msgData.timenum = new Date();
          page1StoreDa.webSocketMsg = msgData;
          msgScroll();
          break;
        case "gift":
          msgData = dataParse;
          msgData.timenum = new Date();
          page1StoreDa.webSocketMsg = msgData;
          msgScroll();
          break;
        case "disConnect":
          logArr.push("顾问挂断挂断或余额不足提示");
          nextTick(async () => {
            store1.leaveVideoFlag = true;
            if (receiveType.value == "1") {
              ElMessage({
                type: "warning",
                message: "Disconnect from the user",
              });
              setTimeout(() => {
                router.push("/");
              }, 2 * 1000);
            } else {
              ElMessage({
                type: "warning",
                message: "The consultant has voluntarily disconnected you",
              });
              refTimers.value.stopTimers = true;
              isRate.value = true;
            }
            await closeLive({});
          });
          break;
      }
    }
  };
};
watch(webSocketMsg, (newValue, oldValue) => {
  chatArr.value.push(newValue);
  if (newValue.type == "gift") {
    setTimeout(() => {
      msgScroll();
    }, 200);
  }
});

// const userN = ref(false);
// const guwenN = ref(false);

//检测视频流
const detectionVideo = () => {
  detectionTimer.value = setInterval(() => {
    if (userN.value || guwenN.value) {
      clearInterval(detectionTimer.value);
      console.log("结束检测视频流");
    } else {
      joinRoomIndex(route.query.roomName);
      initWebsocket();
      console.log("检测视频流");
    }
  }, 10000);
};

//获取摄像头
const getCamera = async () => {
  try {
    isNoCamera.value = false;
    localUserMedia.value = await init();
    localCameraVideo.value.srcObject = await init();
    objVie.camera = "已获取到本地摄像头";
    initWebsocket();
    // isTimer.value = true;
  } catch (err) {
    isNoCamera.value = true;
    objVie.camera = "未获取到本地摄像头";
    console.log("未获取到本地摄像头");
    // 未能获取到摄像头;
    isCameraShow.value = true;
    // isTimer.value = false;
    console.log(new Date(), "new Date1");
    // let msg = {
    //   type: "noCamera",
    // };
    // ws.send(JSON.stringify(msg));
    // LoseCamera();
    loadingFlag.value = false;
    CameraA.value = true;
    return true;
  }
  if (localCameraVideo.value) {
    localCameraVideo.value.play();
  }
};
//多浏览器适配函数
const init = () => {
  if (navigator.mediaDevices) {
    return navigator.mediaDevices.getUserMedia({ audio: true, video: true });
  }
};

// const init = () => {
//   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//     // 使用新的 getUserMedia API
//     return navigator.mediaDevices.getUserMedia({ audio: true, video: true });
//   } else if (navigator.getUserMedia) {
//     navigator.getUserMedia({ audio: true, video: true });
//   } else {
//     console.error("浏览器不支持 getUserMedia");
//     // 这里可以添加不支持处理逻辑
//   }
// };

//关闭摄像头
const closeInit = () => {
  navigator.mediaDevices
    .getUserMedia({ audio: true, video: true })
    .then((stream) => {
      console.log(stream.getTracks(), "streamstream");
      // 停止所有媒体轨道（关闭摄像头和麦克风）
      stream.getTracks().forEach((track) => {
        console.log(track, "tracktracktrack");
        track.stop();
      });
    })
    .catch((error) => {
      console.error("获取媒体流失败: ", error);
    });
};

//意外情况指定时间内关闭loading
const closeLoading = () => {
  let timer = setInterval(() => {
    // if (loadingFlag.value) {
    //   ElMessage({
    //     message: "Failed to obtain the peer video. Procedure!",
    //     type: "warning",
    //   });
    // }
    console.log("关闭loading");
    loadingFlag.value = false;

    clearInterval(timer);
  }, 20 * 1000);
};

// 赠送礼物
async function postGiftRecord(id, item) {
  ElMessageBox.confirm(
    `Are you sure youre going to spend ${item.coin} wires on a gift ?`,
    {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    }
  )
    .then(async () => {
      let data = {
        id: id, // 礼物id
        num: num.value,
        roomIndex: roomName.value, // 房间号
      };
      const { code, msg } = await request.post("/system/giftRecord/add", data);
      if (code == 200) {
        ElNotification({
          title: msg,
          message: "Gift given successfully!",
          type: "success",
        });

        innerDrawer.value = false;
        isGift.value = false;
      } else {
        ElNotification({ title: msg, message: "Gift giving failed!", type: "error" });
      }
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "Cancel gifts",
      });
    });

  return;
}

//返回摄像头状态接口
const LoseCamera = async () => {
  await request.get("/system/liveRoom/inform", {});
};

// 获取聊天室历史信息
async function getHistoryMsg() {
  console.log(roomName.value, "roomName.value,");
  let params = {
    roomId: roomName.value, //房间id
    userType: getType() == "1" ? 2 : 1,
    // userId: '',     //用户id
  };
  const { data, code } = await request.get("/wom-chat-room/chatRoom/getById", { params });
  if (code == 200) {
    chatArr.value = chatArr.value.concat(data.chatInfoList);
    console.log(chatArr.value, "chatArr.value");
  }
  nextTick(() => {
    msgScroll();
  });

  console.log(data, "获取聊天室历史信息");
}

// 列表滚动
function msgScroll() {
  if (msglist.value) {
    requestAnimationFrame(() => {
      msglist.value.scrollTop = msglist.value.scrollHeight;
    });
  }
  if (advisorChatRef.value) {
    requestAnimationFrame(() => {
      advisorChatRef.value.scrollTop = advisorChatRef.value.scrollHeight;
    });
  }
}

//打开 买点数
const openBuyCredits = () => {
  bus.emit("openBuyCredits", true);
};
//send Msg
const postRoomMsg = async () => {
  console.log(token, "token");
  if (!token) {
    ElNotification({
      title: "Error",
      message: "Please login and try again!",
      type: "error",
    });
    return;
  }
  if (!sendText.value) {
    return;
  }
  let data = {
    superId: roomName.value, //房间id
    content: sendText.value, //内容
  };
  const { code, msg } = await request.post("/wom-chat-room/chatRoom/addChatInfo", data);
  if (code == 200) {
    sendText.value = "";
    liaotian.value = false;
  } else {
    ElNotification({ title: "Error", message: msg, type: "error" });
  }
  msgScroll();
};
</script>

<style lang="scss" scoped>
.callplayer {
  width: 100%;
  min-width: 990px;
  height: 100%;
  background: linear-gradient(180deg, lightblue, #fff, #fff);
}

.middle {
  height: 770px;
  width: 990px;
  margin: auto;

  background: linear-gradient(180deg, lightblue, #fff, #fff);

  .content {
    margin: auto;

    .mid-video {
      width: 100%;
      display: flex;
      padding-top: 30px;

      .video {
        width: 600px;

        .video-top {
          height: 450px;

          .video-class {
            position: relative;
            height: 100%;
            width: 600px;

            .connecting {
              position: absolute;
              top: -50px;
              right: 195px;
              font-weight: 700;
              font-size: 30px;
              color: #2f906d;
            }
          }

          .video-small {
            position: absolute;
            bottom: 0px;
            right: 0px;
          }
        }
      }

      .chat-all {
        width: 350px;
        height: 400px;

        .chat-top {
          position: relative;
          width: 350px;
          height: 170px;
          text-align: center;
          overflow: hidden;

          .img {
            width: 100%;
            height: 100%;
          }

          img {
            width: 350px;
            height: 100%;
            object-fit: cover;
          }

          .chat-close {
            position: absolute;
            top: 8px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .close {
              background-color: #888282;
              padding: 4px;
              margin-right: 3px;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }

        .chat-messgae {
          width: 100%;
          height: 281px;
          background-color: rgba(0, 0, 0, 0.3);
          font-size: 12px;
          line-height: 30px;
          color: #ffffff;

          p {
            margin-left: 6px;
          }
        }

        .send-text {
          height: 37px;

          :deep(.el-input__wrapper) {
            border-radius: 0;
            height: 36px;
          }

          :deep(.el-button) {
            border-radius: 0;
            height: 36px;
          }
        }
      }
    }
  }
}

.balance {
  display: flex;
  justify-content: space-around;
  color: #ffe400;

  div {
    height: 35px;
    line-height: 35px;
  }
}

.video-gif {
  width: 100%;
  display: flex;
  border-radius: 5px;

  ul {
    width: 550px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #221c1c;

    .gif-send {
      font-size: 12px;
      padding: 0 18px;
      text-align: center;

      .send {
        color: #f3f5f8;
        font-size: 12px;
        margin: auto;
      }
    }

    .moji-item {
      .img-wrap:hover {
        background: #fff;
        box-shadow: 0 0 14px 2px #ffcc00;
      }

      .img-wrap {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img:hover {
          box-shadow: 0 0 10px 0 #ffcc00;
          background-color: #fff;
        }
      }
    }

    li {
      list-style: none;
      width: 23px;
      height: 25px;
      padding-top: 5px;
      cursor: pointer;
      margin-left: 10px;

      img {
        width: 20px;
        height: 20px;
        border-radius: 20px;
      }
    }
  }

  .gif-icon {
    cursor: pointer;
  }

  .gif-messg {
    width: 200px;
    height: 37px;
    background: #ffe400;
    line-height: 37px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }

  .buy-thing {
    width: 110px;
    height: 37px;
    background: #222222;
    line-height: 37px;
    // text-align: center;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
  }
}

.messages {
  width: 100%;
  height: 280px;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;

  .showTipMsg {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #0fd6cc;
    font-size: 16px;
  }

  ul {
    // padding-top: 174px;
    height: 100%;
    overflow-y: auto;

    li {
      list-style: none;
      color: #ffffff;
      font-size: 12px;
      padding: 5px;
    }
  }
}
</style>
